import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../../components/Layout'
import WorkRoll from '../../components/WorkRoll'
import Heading from '../../components/Heading'

export default class BlogIndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <Helmet titleTemplate="Works | %s" />
        <Heading heading="Works" />
        <section className="section">
          <div className="container">
            <div className="content">
              <WorkRoll />
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}
