import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, StaticQuery } from 'gatsby'
import PreviewCompatibleImage from './PreviewCompatibleImage'

class ImageRoll extends React.Component {
  onMouseEnter(e) {
    const target = e.target.closest('article')
    if (target) {
      target.classList.add('entered')
    }
  }

  onMouseLeave(e) {
    const target = e.target.closest('article')
    if (target) {
      target.classList.remove('entered')
    }
  }

  render() {
    const { data } = this.props
    const { edges: posts } = data.allMarkdownRemark

    return (
      <div className="columns is-multiline imageroll">
        {posts &&
          posts.map(({ node: post }) => (
            <article
              key={post.id}
              className="column is-one-quarter-widescreen is-half-tablet"
              style={{ position: 'relative' }}
            >
              <Link
                className=""
                to={post.fields.slug}
                style={{ margin: 'auto' }}
                onMouseEnter={this.onMouseEnter}
                onMouseLeave={this.onMouseLeave}
              >
                <div className="work-description is-size-4">
                  {post.frontmatter.title}
                </div>
                <PreviewCompatibleImage
                  imageInfo={{
                    image: post.frontmatter.featuredimage,
                    alt: post.title,
                  }}
                  className="grayscale"
                />
              </Link>
            </article>
          ))}
      </div>
    )
  }
}

export default ImageRoll
