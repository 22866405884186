import React from 'react'

const Footer = class extends React.Component {
  render() {
    const { heading } = this.props
    return (
      <div className="section">
        <h1 className="container is-size-3 heading-text">{heading}</h1>
      </div>
    )
  }
}

export default Footer
